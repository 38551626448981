import React, { useState } from 'react'
export const AgoraContext=React.createContext();
export const AgoraProvider=(props)=>{
    const [token, settoken] = useState('')
    const [channel, setchannel] = useState('')
    const [calluid, setcalluid] = useState('');
    const [callsid, setcallsid] = useState('');
    const [resourceIds, setresourceIds] = useState('');

    const updateuid = (newuid) => {
        console.log("🚀 ~ updateuid ~ newuid: ===", newuid)
        setcalluid(newuid);
      };


    return(
        <AgoraContext.Provider value={[token, settoken,channel, setchannel,calluid, updateuid,callsid, setcallsid,resourceIds, setresourceIds]}>
            {props.children}
        </AgoraContext.Provider>
    )
}