 //export const GLOBAL_URL = 'https://dev.easycalls.in:3036'
 //export const GLOBAL_URL = 'http://192.168.1.21:3001'
 //export const GLOBAL_URL = 'https://stageauditapi.easycalls.in:3003'
 export const GLOBAL_URL = 'https://auditwebapi.easycalls.in:3002'
 //export const GLOBAL_URL = 'http://localhost:3001'
 export const MAP_KEY = 'AIzaSyDfFtzK75LUH74CIi-R6cNFO81mQNtafsA';


 export const AGORA_APP_ID = "b13f7540466747e6a102327255673a59"
 export const AGORA_APP_CERTIFICATE = "b4185bb41b04468b9300bca922553949"
 export const AGORA_CUSTOMER_KEY= "cdcedda64ba64ca9a1a1e67ed38a7acc"
 export const AGORA_CUSTOMER_SECREAT = "b888f21682a04a589723970e5c44ca3a"
 export const S3_ACCESS_KEY = "AKIAXYKJVMHA6PJDL43U"
 export const S3_SECRET_KEY= "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"
 export const S3_APP_BUCKET= "infraauditapplication"
 export const S3_APP_REGION= 14
 export const S3_APP_REGION_NAME= 'ap-south-1'
 export const S3_APP_VENDOR= 1




