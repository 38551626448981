import React, { useState, useEffect, Fragment } from 'react';
import { apiCall } from '../utils/httpClient';
import EndPoints from '../utils/apiEndPoints';
import { Link } from "react-router-dom";

export default function Header(props) {

    const logout = async() => {
        const { data } = await apiCall('get', EndPoints.LOGOUT)
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("token");
        localStorage.removeItem("calluid");
        localStorage.removeItem("resourceId");
        localStorage.removeItem("callsid");
        localStorage.removeItem("channel");
        localStorage.removeItem("token");
        localStorage.removeItem("latitude");
        localStorage.removeItem("longitude");
        localStorage.removeItem("locationaddress");
        window.location.assign("/")
      }

    return (
        <Fragment>
            <header className="bg_dark header-area">
                <div className="header-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_page">
                                    <div className="navigation">
                                        <nav className="navbar navbar-expand-lg navbar-light ">
                                            <button className="btn menu-btn" id="menubtn" onClick={()=>props.handleSidebar()}>
                                                {" "}
                                                <span className= {!props.menuShow ?"fas fa-bars":"fas fa-times"} />{" "}
                                            </button>
                                            <div
                                                className="collapse navbar-collapse sub-menu-bar"
                                                id="navbarSupportedContent"
                                            ></div>

                                              <span className="menu-btn log-btn" id="menubtn" onClick={()=>logout()}>
                                                {"Logout"}
                                               {/*  <span className= {!props.menuShow ?"fas fa-bars":"fas fa-times"} /> */}
                                            </span>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>



        </Fragment>
    )

}