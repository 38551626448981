import axios from 'axios'
import { GLOBAL_URL,MAP_KEY,AGORA_APP_ID,AGORA_CUSTOMER_KEY,AGORA_CUSTOMER_SECREAT } from './constants'

let plainCredentials =
AGORA_CUSTOMER_KEY + ":" + AGORA_CUSTOMER_SECREAT;
let base64ToString = Buffer.from(plainCredentials, "utf-8").toString("base64");

const httpClient = axios.create({
    baseURL: `${GLOBAL_URL}/`,
});

export function setDefaultHeader(header, value) {
    httpClient.defaults.headers.common[header] = value
}

export async function apiCall(method, url, data, header = {
    'Content-Type': 'application/json'
}) {
    try {
       // console.log('url', url)
        const res = await httpClient({
            method,
            url,
            data,
            headers: header,
            withCredentials: false
        })
     //   console.log('res', res)
        return res
    } catch (error) {
        if (error.response) {
            console.log('Error data : ', error.response.data);
            console.log('Error status : ', error.response.status);
            console.log('Error headers : ', error.response.headers);
        } else if (error.request) {
            console.log('Error request : ', error.request);
        } else {
            console.log('Error message : ', error.message);
        }
        console.log("Error config", error.config);
        // console.log("errorresponse", error.response);
        console.log("Error", error);
        return false
    }
}

export async function getLocation(latitude, longitude)
{
  try{
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAP_KEY}`);
     if (response.data.results.length > 0)
     {
       const locationName = response.data.results[0].formatted_address;
      //  setData(locationName);
       console.log("Resposne ------>>>",locationName);
       return locationName;
     }
   
  }catch(error)
  {
     console.error("Error in getting data :- ", error);
  }
}



export const callresourceIDacquire = async (body) => {
    try {
      let resp = await axios.post(
        `https://api.agora.io/v1/apps/${AGORA_APP_ID}/cloud_recording/acquire`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${base64ToString}`, //the token is a variable which holds the token
            
          },
        }
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
};
export const callRecordStart = async (resourceId, body) => {
    try {
      let resp = await axios.post(
        `https://api.agora.io/v1/apps/${AGORA_APP_ID}/cloud_recording/resourceid/${resourceId}/mode/mix/start`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${base64ToString}`, //the token is a variable which holds the token
          },
        }
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
};

export const callRecordStop = async (resourceIds,callsid,body) => {
  try {
    let resp = await axios.post(
      `https://api.agora.io/v1/apps/${AGORA_APP_ID}/cloud_recording/resourceid/${resourceIds}/sid/${callsid}/mode/mix/stop`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${base64ToString}`, //the token is a variable which holds the token
          
        },
      }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};
