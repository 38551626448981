export default {
   JWTTOKEN: 'api/v1/tokenGenrate',
   SIGNIN: 'api/v1/userAuth/bmLogin',
   AUDITLIST: 'api/v1/audit/getBmAuditList',
   ACTIONABLEDETAIL: 'api/v1/question/getActionableDetail',
   ACTIONABLE_SUBMIT: 'api/v1/question/actionableSubmit',
   QUESTIONLIST: 'api/v1/question/bmQuestionlist',
   CAPTURINIMAGE: 'api/v1/question/updateCaptureimage',
   GETIMAGEREQUEST:'api/v1/question/getImagedata',
   AGORA_TOKEN:'/api/v1/audit/getAgoraToken',
   LOGOUT:"api/v1/userAuth/logout",
   GETIMAGESONLINE:"api/v1/question/getquestionImage",
   UPLOADONLINEVIDEO:"api/v1/audit/uploadonlinevideo",
   CHECKBMLOCATION:"api/v1/audit/checkbmlocation",
   CAPTUREAGORAIMAGE:"api/v1/agoraApi/captureAgoraImg",
   CAPTUREAGORAVIDEO:"api/v1/agoraApi/captureAgoraVideoAquir"

   
}