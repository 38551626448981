import React, { useContext, useEffect, useRef, useState } from "react";
import NotifyScreen from "./Component/notify";
import { useParams, useHistory } from "react-router-dom";
import { AgoraContext } from "../../Context/AgoraContext";
import AgoraRTC from "agora-rtc-sdk-ng";
import { socket } from "../utils/Client";
import EndPoints from "../utils/apiEndPoints";
import { apiCall, getLocation, callRecordStart, callresourceIDacquire, callRecordStop } from "../utils/httpClient";
import { QuestionContext } from "../../Context/QuestionContext";
import { useMyContext } from '../../Context/MyDetailContext';
import AWS from 'aws-sdk';

import {
  S3_ACCESS_KEY,
  S3_SECRET_KEY,
  S3_APP_BUCKET,
  S3_APP_REGION,
  S3_APP_VENDOR,
  AGORA_CUSTOMER_KEY,
  AGORA_CUSTOMER_SECREAT,
  AGORA_APP_ID,
  S3_APP_REGION_NAME
} from '../utils/constants'
import Swal from "sweetalert2";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
let rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
};
let rtcclient = {
  client: null
};
var video;
const NotifyView = () => {
  const { myState, updateMyState } = useMyContext();
  let { audit_id } = useParams();
  const history = useHistory();
  const [questionn, setquestionlist] = useState();
  const [online, setonline] = useState(false);
  const [qty, setqty] = useState();
  var [intervalId, setintervalId] = useState("");
  const [auditStart, setAuditStart] = useState(false);
  const [imgurl, setimgurl] = useState("");
  const [requestData, setrequestData] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [token, settoken, channel, setchannel] = useContext(AgoraContext);
  const [rating, setRating] = useState(0);
  const [remark, setremark] = useState("");
  const [front, setFront] = useState(true);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [camToggle, setcamToggle] = useState(true);
  const camToggleRef = useRef(true);
  const [micToggle, setmicToggle] = useState(true);
  const [bmplay, setbmplay] = useState(1);
  const [rmmplay, setrmmplay] = useState(1);
  const [actionablescore, setactionablescore] = useState(0);
  const [questionList, setactionalequestionList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [resourceIds, setresourceIds] = useState("");
  const [calluid, setcalluid] = useState("");
  const [callsid, setcallsid] = useState("");
  const [totalScrore, settotalScore] = useState(0);
  const [question, setquestion] = useContext(QuestionContext);
  const [recordingObject, setRecordingObject] = useState(false);
  var recordingObjectStart = {};
  var localchannel = localStorage.getItem('channel');
  var localtoken = localStorage.getItem('token');
  var recordingStopFlag = false;
  const options = {
    appId: AGORA_APP_ID,
    channel: channel ? channel : localchannel,
    token: token ? token : localtoken,

  };


  const [isOnline, setIsOnline] = useState(true);

  function checkInternetConnection() {
    if (navigator.onLine) {
      //setOnlineBM(1)
      Swal.fire({
        icon: "success",
        title: "Connected!",
        text: 'Internet connection is stable.',
        confirmButtonColor: "#457fff"

      });
    } else {
      // setOnlineBM(0)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'No internet connection detected. Please check your network settings and try again!',
        confirmButtonColor: "#457fff"

      });
    }
  }

  window.addEventListener("offline", checkInternetConnection);
  window.addEventListener("online", checkInternetConnection);

  if (!isOnline) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: 'No internet connection detected. Please check your network settings and try again!',
      confirmButtonColor: "#457fff"

    });
  }
  async function startRecordingCall(bmUId) {

    try {
      const params = { audit_id: audit_id, type: 1 };
      const { data } = await apiCall(
        "post",
        '/api/v1/agoraApi/getRecordingToken',
        params
      );

      console.log("🚀 ~ startRecordingCall ~ data:", data)
      if ((data.status = 200)) {
        console.log("startRecordingCall", data);
        
        var uidRecordinglocal = localStorage.getItem('uidRecording');
        var uidRecording = 12580
        console.log("🚀 ~ startRecordingCall ~ uidRecordinglocal:", uidRecordinglocal)
        
        rtcclient.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        var uidRecording = await rtcclient.client.join(
          options.appId,
          data.channelName,
          data.token,
          12580
        );
        await localStorage.setItem('uidRecording', uidRecording); 

     


        const paramStart = { channel: data.channelName, token: data.token, localuid: uidRecording, bmUid: bmUId };
        const dataStartCall = await apiCall(
          "post",
          '/api/v1/agoraApi/captureAgoraVideo',
          paramStart
        );
        //console.log("start recording", dataStart);
        if (dataStartCall.status == 200) {
          console.log("start recording", dataStartCall.data);
          setRecordingObject(dataStartCall.data);
          recordingObjectStart = dataStartCall.data;
          recordingObjectStart.audit_id = audit_id;
          console.log("🚀 ~ startRecordingCall ~ recordingObjectStart:", recordingObjectStart)
          /* setTimeout(() => {
            console.log("Stop Recording");
            StopRecordingCall()

          }, 300000); */
        }

      } else {
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function StopRecordingCall() {
    console.log("recordingObject-cacel", recordingObjectStart);
    if(Object.keys(recordingObjectStart).length > 0){
     if (recordingStopFlag == false) {
      const data = await apiCall(
        "post",
        '/api/v1/agoraApi/StopRecording',
        recordingObjectStart
      );
      console.log(data);
      recordingStopFlag = true;
    }
   }
  }

  async function startBasicCall() {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    /*  if (localuid && localuid !== '') {
  
  
      var uid = await rtc.client.join(
          options.appId,
          options.channel,
          options.token,
          localuid
        );
        setOnlineBM(1)
      } else {
  
  
        var uid = await rtc.client.join(
          options.appId,
          options.channel,
          options.token,
          null
        );
      } */
    var uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token,
      987654321,
    );
    setOnlineBM(1)
    setcalluid(uid)

    console.log("🚀 ~ startBasicCall ~ uid:=====", uid)
    await localStorage.setItem('calluid', uid);
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    var d = await rtc.client.publish([
      rtc.localAudioTrack,
      rtc.localVideoTrack,
    ]);
    const remoteVideoTrack = rtc.localVideoTrack;
    const playerContainer = document.getElementById("player");
    playerContainer.style.width = "100%";
    playerContainer.style.height = "480px";
    remoteVideoTrack.play(playerContainer);
    /*  const resourceIdcall = await AgoraRTC.getRtcResourceID();
     setResourceIds(resourceIdcall) */
    // Get RtcResourceID using Agora RESTful API

    /* if(resourceIds === ""){ */
 /*    try {

      var apiBody = {
        cname: options.channel,
        uid: uid.toString(),
        clientRequest: {
          resourceExpiredHour: 24, // Set the expiration time for the resource ID
          scene: 0,
        },
      }

      var apiresp = await callresourceIDacquire(apiBody);
      console.log("🚀 ~ startBasicCall ~ apiresp:", apiresp)
      if (apiresp?.status === 200) {
        console.log("🚀 ~ Record Aquir ~ apiresp?.data?.resourceId:====", apiresp?.data?.resourceId)
        setresourceIds(apiresp?.data?.resourceId)
        await localStorage.setItem('resourceId', apiresp?.data?.resourceId);
       
        //setcallsid('patidar')
        //startRecording(uid,apiresp?.data?.resourceId)
        ////startRecording()
      }

    } catch (error) {
      console.error('Error fetching RtcResourceID:', error);
    } */

      //////
     
    //startRecordingCall(uid); ///comment for recording


    rtc.client.on("user-published", async (user, mediaType) => {
      console.log("🚀 ~ rtc.client.on ~ mediaType:", mediaType)
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      setonline(true);


      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        const playerContainer = document.createElement("div");
        playerContainer.id = user.uid.toString();
        playerContainer.style.width = "0px";
        playerContainer.style.height = "0px";
        document.body.append(playerContainer);
        remoteVideoTrack.play(playerContainer);
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });


    setTimeout(() => {
      console.log("Call Drop");
      leaveCall()

    }, 300000);


  }

  async function leaveCall() {
    if (rtc.localAudioTrack != null || rtc.localVideoTrack != null) {
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();
      await rtc.client.leave();
      //await rtcclient.client.leave();//comment for recording
      //StopRecordingCall()//comment for recording
      stopRecording()
      setOnlineBM(0)
      //anotherFunction()
    }
  }

  async function leaveCallios() {
    if (rtc.localAudioTrack != null || rtc.localVideoTrack != null) {
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();
      await rtc.client.leave();

    }
  }

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  useEffect(() => {
    // startRecordingCall();
    startBasicCall();
    return () => {
      leaveCall();
    };
  }, []);

  useEffect(() => {
    socket.on("checker", (data) => {
      if (data.socketEvent == `checker${audit_id}`) {
        setImagePreview([]);
      }
    });
    socket.on("bm_online", (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setbmplay(data.data.bm_online);
      }
    });
    socket.on("rating_sent", (data) => {
      if (data.audit_id == audit_id) {
        setRating(data.val);
      }
    });
    socket.on("remark_sent", (data) => {
      if (data.audit_id == audit_id) {
        setremark(data.val);
      }
    });
    socket.on("qtyview_sent", (data) => {
      if (data.audit_id == audit_id) {
        setqty(data.qty);
      }
    });
    socket.on("startcalling", (data) => {
      if (data.socketEvent == `getQuestionList${audit_id}`) {
        if (data.status == 200) {
          setquestionlist(data.data);
          setimgurl(data.base_url);
          setAuditStart(true);
          setRating(0);
          setremark("");
          setqty("");
          setactionablescore(0);
        } else {
          window.location.assign(`/actionable/${audit_id}`);
        }
      }
    });
    socket.on("completeQuestionAudit", (data) => {

      if (data.audit_id == audit_id) {
        //StopRecordingCall();
        //stopRecording(data.audit_id)
        //window.location.assign(`/actionable/${audit_id}`);
        setactionablescore(1);
        //handleActionable(audit_id);
      }
    });
    socket.on("getpreviousquestion", (data) => {
      if (data.socketEvent == `previousquestion${audit_id}`) {
        setquestionlist(data.data);
        setimgurl(data.base_url);
        setAuditStart(true);
        setRating(data.answer.score_range);
        setremark(data.answer.remark);
      }
    });


    socket.on("captureImageAccept", (data, callback) => {
      console.log("🚀 ~ socket.on ~ data:***", data)
      if (data.socketEvent == `captureImageAccept${audit_id}`) {
        handleUploadimage();
      }
    });

    ////for video Record
    socket.on("videorecordAccept", (data, callback) => {
      if (data.socketEvent == `videorecordAccept${audit_id}`) {
      }
    });




    socket.on("deleteCaptureReponse", (data, callback) => {
      if (data.status == 200) {
        if (data.socketEvent == `deleteCaptureImg${audit_id}`) {
          setimgurl(data.base_url);
          setImagePreview(data?.data);
        }
      }
    });
  }, []);

  const handleUploadimage = async () => {
    const params = { audit_id: audit_id };
    console.log("🚀 ~ handleUploadimage ~ params:***", params)
    socket.emit("getImagedata", params, (data) => {
      console.log("🚀 ~ socket.emit ~ data.socketEvent:***", data.socketEvent)
      if (data.socketEvent == `getImagedata${audit_id}`) {
        uploadIMGDataSocket(data);
      }
    });
  };

  const handelBmStatus = async (message) => {
    const params = { audit_id: audit_id, message: message };
    console.log("🚀 ~ handelBmStatus ~ params:***", params)
    socket.emit("handelBmStatus", params, (data) => {
      console.log("🚀 ~ socket.emit ~ data.socketEvent:***", data)
    });


  };

  const uploadIMGDataSocket = (data) => {
    console.log("🚀 ~ uploadIMGDataSocket ~ data:***", data)
    if (data.status === 200) {
      if (data?.data[0]?.status === 0) {
        setrequestData(data.data[0]);
        captuepic(data.data[0].id, data.data[0].question_id);
      }
    }
  };


  const captuepicold = async (id, question_id) => {
    const player = document.getElementById("player1");
    const imageWidth = document.getElementById("player1").offsetWidth;
    const imageHeight = document.getElementById("player1").offsetHeight;
    const outputCanvas = document.getElementById("output");
    const context = outputCanvas.getContext("2d");
    outputCanvas.width = imageWidth;
    outputCanvas.height = imageHeight;

    if (navigator.platform !== "iPhone") {
      await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: camToggleRef.current === true ? "user" : "environment",
          },
          audio: false,
        })
        .then((stream) => {
          player.srcObject = stream;

          setTimeout(() => {
            context.drawImage(player, 0, 0, imageWidth, imageHeight);
            outputCanvas.toBlob((blob) => {
              //   var url = URL.createObjectURL(blob);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64data = reader.result;
                handleCaptureImage(base64data, id, question_id);
              };
            });
          }, 400);
          setTimeout(() => {

            stopnewCapture(stream);
          }, 450);
        })
        .catch((error) => {
          //alert(error);
        });
    } else {

      await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: camToggleRef.current === true ? "user" : "environment",
          },
          audio: false,
        })
        .then((stream) => {
          player.srcObject = stream;

          setTimeout(() => {
            context.drawImage(player, 0, 0, imageWidth, imageHeight);
            outputCanvas.toBlob((blob) => {
              //   var url = URL.createObjectURL(blob);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64data = reader.result;
                handleCaptureImage(base64data, id, question_id);
              };
            });
          }, 400);
          setTimeout(() => {
            leaveCallios()
            setmicToggle(true)
            setcamToggle(true)
            //toggleCam(false)
            camToggleRef.current = true
            stopnewCapture(stream);
            setTimeout(() => {
              startBasicCall()
            }, 500);
          }, 550);
        })
        .catch((error) => {
          console.log("🚀 ~ captuepic ~ error:", error)
          //alert(error);
        });
    }

    // }, 600);
  };
  const captuepic_BKP_JS = async (id, question_id) => {
    const player = document.getElementById("player1");
    const imageWidth = document.getElementById("player1").offsetWidth;
    const imageHeight = document.getElementById("player1").offsetHeight;
    const outputCanvas = document.getElementById("output");
    const context = outputCanvas.getContext("2d");
    outputCanvas.width = imageWidth;
    outputCanvas.height = imageHeight;

    try {
      // Get media stream
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: camToggleRef.current === true ? "user" : "environment",
        },
        audio: false,
      });

      // Assign stream to player
      player.srcObject = stream;

      // Capture image after a delay
      setTimeout(() => {
        context.drawImage(player, 0, 0, imageWidth, imageHeight);
        outputCanvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            handleCaptureImage(base64data, id, question_id);
          };
        });
        console.log("🚀 ~ setTimeout ~ navigator.platform:", navigator.platform)
        if (navigator.platform === "iPhone") {

        }
        document.getElementsByClassName('agora_video_player').style.visibility = 'hidden';
        // Close the stream after capturing the image
        stopnewCapture(stream);

        // Perform any post-capture operations here

      }, 400);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  const captuepic = async (id, question_id) => {
   try {
      var localuid = localStorage.getItem('calluid');
      console.log("🚀 ~ captureAgoraImg ~ localuid: =====", localuid)

      const params = {
        localuid: localuid,
        channel: options.channel,
        token: options.token,
      }

      const { data } = await apiCall(
        "post",
        EndPoints.CAPTUREAGORAIMAGE,
        params
      );
      console.log("🚀 ~ awaitsocket.emit ~ data:55555", data)
      if (data.status == 200) {
        //handelBmStatus('Image Capture Successfully!')
        handleCaptureImage(data.imageName
          , id, question_id);
      } else {

        handelBmStatus('Due to Network error Image not Capture')
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: 'Due to Network error Image not Capture',
          confirmButtonColor: "#457fff"

        });

      }

    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const handleCaptureImage = async (blob, id, question_id) => {
    console.log("🚀 ~ handleCaptureImage ~ blob:55555", blob)

    const params = {
      audit_id: audit_id,
      question_id: question_id,
      id: id,
      image_data: blob,
      latitude: localStorage.getItem('latitude'),
      longitude: localStorage.getItem('longitude'),
      locationbm: localStorage.getItem('locationaddress')

    };
    console.log("🚀 ~ file: index.js:299 ~ handleCaptureImage ~ params:", params)
    console.log("🚀 ~ awaitsocket.emit ~ params:", params)

    // alert(JSON.stringify(params));
    await socket.emit("updateCaptureimage", params, async (data) => {
      // alert("data");
      if (data.status == 200) {
        // alert("updateCaptureimage")
        if (data.socketEvent == `updateCaptureimage${audit_id}${question_id}`) {
          const params = {
            audit_id: audit_id,
            question_id: question_id,

          };
          const { data } = await apiCall(
            "post",
            EndPoints.GETIMAGESONLINE,
            params
          );
          console.log("🚀 ~ awaitsocket.emit ~ data:", data)
          if (data.status = 200) {
            setimgurl(data.base_url);
            setImagePreview(data?.image_data);
            setrequestData(data.data);
          }



        }
      }
    });


  };


  const handleCaptureImage_BKP_location = async (blob, id, question_id) => {

    const params = {
      audit_id: audit_id,
      question_id: question_id,
      id: id,
      latitude: '',
      longitude: '',
      image_data: blob,

    };
    console.log("🚀 ~ file: index.js:299 ~ handleCaptureImage ~ params:", params)
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted" || result.state === "prompt") {

            console.log("🚀 ~ file: index.js:299 ~ handleCaptureImage ~ params:", params)
            navigator.geolocation.getCurrentPosition(async function (position) {
              console.log("🚀 ~ position:", position)

              if (position.coords.latitude !== '' && position.coords.longitude !== '') {

                params.latitude = position.coords.latitude
                params.longitude = position.coords.longitude

                const res = await getLocation(position.coords.latitude, position.coords.longitude);
                console.log("🚀 ~ file: index.js:310 ~ res: remi", res)
                params.locationbm = res
                console.log("🚀 ~ awaitsocket.emit ~ params:", params)

                // alert(JSON.stringify(params));
                await socket.emit("updateCaptureimage", params, async (data) => {
                  // alert("data");
                  if (data.status == 200) {
                    // alert("updateCaptureimage")
                    if (data.socketEvent == `updateCaptureimage${audit_id}${question_id}`) {
                      const params = {
                        audit_id: audit_id,
                        question_id: question_id,

                      };
                      const { data } = await apiCall(
                        "post",
                        EndPoints.GETIMAGESONLINE,
                        params
                      );
                      console.log("🚀 ~ awaitsocket.emit ~ data:", data)
                      if (data.status = 200) {
                        setimgurl(data.base_url);
                        setImagePreview(data?.image_data);
                        setrequestData(data.data);
                      }



                    }
                  }
                });


              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Enable your device, location services and grant the necessary permissions! let long',
                  confirmButtonColor: "#457fff"

                });

              }

            })
          } else if (result.state === "denied") {

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: 'Enable your device, location services and grant the necessary permissions! denied',
              confirmButtonColor: "#457fff"

            });


          }
        });
    } else {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'Geolocation is not supported by this browser.',
        confirmButtonColor: "#457fff"

      });

    }

  };

 const toggleCam = (state) => {
    AgoraRTC.getDevices().then((devices) => {
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });
      console.log("🚀 ~ videoDevices ~ videoDevices:", videoDevices)
      console.log("🚀 ~ videoDevices ~ videoDevices.length", videoDevices.length)
      if (videoDevices.length > 1) {
        if (state) {
          rtc.localVideoTrack
            .setDevice(videoDevices[0].deviceId)
            .then(() => { })
            .catch((e) => {
              console.log(e);

            });
        } else {

          if (navigator.platform === "iPhone") {

            rtc.localVideoTrack
              .setDevice(videoDevices[videoDevices.length - 1].deviceId)
              .then(() => { })
              .catch((e) => {
                console.log(e);

              });

          } else {

            rtc.localVideoTrack
              .setDevice(videoDevices[1].deviceId)
              .then(() => { })
              .catch((e) => {
                console.log(e);

              });

          }



          /*  if (videoDevices.length === 3) {
            rtc.localVideoTrack
             .setDevice(videoDevices[videoDevices.length - 1].deviceId)
             .then(() => { })
             .catch((e) => {
               console.log(e);
 
             }); 
           }else{
           rtc.localVideoTrack
             .setDevice(videoDevices[videoDevices.length - 1].deviceId)
             .then(() => { })
             .catch((e) => {
               console.log(e);
 
             });
           } */
        }
      } else {
        console.log("No such device to toggle");
      }
    });
  };
  const toggleAudio = async (state) => {
    setmicToggle(!micToggle);
    rtc.localAudioTrack.setEnabled(state);
  };
  const stopnewCapture = async (stream) => {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  };

  const setOnlineBM = (val) => {
    console.log("🚀 ~ setOnlineBM ~ val:", val)
    setbmplay(val);
    // rtc.localAudioTrack.setEnabled(val==0?false:true)
    // setmicToggle(val==0?false:true)
    var param = {
      type: 1,
      audit_id: audit_id,
      status: val,
    };
    socket.emit("pauseOnlineAudit", param, (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setrmmplay(data.data.rmm_online);
        setbmplay(data.data.bm_online);
      }
    });
  };

  const handleActionable = async (audit_id) => {
    try {
      const params = { audit_id: audit_id, type: 1 };
      const { data } = await apiCall(
        "post",
        EndPoints.ACTIONABLEDETAIL,
        params
      );
      if ((data.status = 200)) {
        setactionalequestionList(data.data);
        settotalScore(data.score);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuestion = (item) => {
    setquestion(item);
    window.location.assign(`/actionquestion`);
  };


  const startRecording = async (uid = "", resourceId = "") => {


    /*  var localuid  = localStorage.getItem('calluid');
     var localresourceIds  = localStorage.getItem('resourceId'); */

    var localuid = uid;
    var localresourceIds = resourceId;
    console.log("🚀 ~ startRecording ~ localuid:====", localuid)
    console.log("🚀 ~ startRecording ~ localresourceIds:====", localresourceIds)

    let apiBody = {
      cname: options.channel,
      uid: localuid.toString(),
      clientRequest: {
        token: options.token,
        recordingConfig: {
          channelType: 0,
          streamTypes: 2,
          audioProfile: 1,
          videoStreamType: 0,
          maxIdleTime: 120,
          transcodingConfig: {
            width: 800,
            height: 600,
            fps: 20,
            bitrate: 500,
            mixedVideoLayout: 0,
            backgroundColor: "#FF0000",
            /* subscribeVideoUids: [
              localuid.toString()
          ], */

            /*  "subscribeUidGroup": 0 */
          },
          // decryptionMode: 6,
        },
        recordingFileConfig: {
          avFileType: ["mp4", "hls"],
        },
        storageConfig: {
          vendor: S3_APP_VENDOR,
          region: S3_APP_REGION,
          bucket: String(S3_APP_BUCKET),
          accessKey: String(S3_ACCESS_KEY),
          secretKey: String(S3_SECRET_KEY),
          fileNamePrefix: [
            `VirtualReviewVideo`,
          ]
        },
      },
    };


    let apiresp = await callRecordStart(localresourceIds, apiBody);
    if (apiresp?.status === 200) {

      console.log("🚀 ~ startRecording ~ apiresp:====", apiresp?.data?.sid)
      setcallsid(apiresp?.data?.sid)
      await localStorage.setItem('callsid', apiresp?.data?.sid);
      /*  setResourceId(apiresp.data.resourceId);
       setSid(apiresp.data.sid);
       saveAudioUrl(apiresp.data.sid); */
    }




  };
  const stopRecording = async () => {




    var localuid = localStorage.getItem('calluid');
    var localresourceIds = localStorage.getItem('resourceId');
    var localsid = localStorage.getItem('callsid');


    /*  var localuid  = calluid;
     var localresourceIds  = resourceIds;
     var localsid  = callsid; */
    /*  console.log("🚀 ~ stopRecording ~ localuid:====", localuid)
    console.log("🚀 ~ stopRecording ~ localresourceIds:====", localresourceIds)
    console.log("🚀 ~ stopRecording ~ localsid:====", localsid)
 
    setLoader(true)
     try {
      var apiBody = {
        cname: options.channel,
        uid: localuid.toString(),
        clientRequest: {
        },
      }
      let apiresp = await callRecordStop(localresourceIds, localsid, apiBody);
      console.log("🚀 ~ stopRecording ~ apiresp:", apiresp)
      if (apiresp?.status === 200) {
         var filename = apiresp?.data?.serverResponse?.fileList[0].fileName
         const params = { audit_id: audit_id,videofile : filename }
        const { data } = await apiCall('post', EndPoints.UPLOADONLINEVIDEO, params)
        if (data.status = 200) {
          history.push('/dashboard');
        } else {
          history.push('/dashboard');
        }
     }
    } catch (error) {
      console.error('Error fetching RtcResourceID:', error);
    }   */


    history.push('/dashboard');
    //window.location.assign("/dashboard")

  };
  const backtodashboard = async () => {
    history.push('/dashboard');
  };
  return (
    <>
      <NotifyScreen
        question={questionn}
        imgurl={imgurl}
        online={online}
        setonline={setonline}
        auditStart={auditStart}
        requestData={requestData}
        imagePreview={imagePreview}
        setrequestData={setrequestData}
        handleCaptureImage={handleCaptureImage}
        token={token ? token : localtoken}
        channel={channel ? channel : localchannel}
        rating={rating}
        remark={remark}
        front={front}
        setFront={setFront}
        videoConstraints={videoConstraints}
        facingMode={facingMode}
        handleClick={handleClick}
        rtc={rtc}
        toggleCam={toggleCam}
        toggleAudio={toggleAudio}
        camToggle={camToggle}
        setcamToggle={setcamToggle}
        micToggle={micToggle}
        setmicToggle={setmicToggle}
        camToggleRef={camToggleRef}
        qty={qty}
        bmplay={bmplay}
        setbmplay={setbmplay}
        setOnlineBM={setOnlineBM}
        handleActionable={handleActionable}
        handleQuestion={handleQuestion}
        actionablescore={actionablescore}
        questionList={questionList}
        totalScrore={totalScrore}
        startRecording={startRecording}
        stopRecording={stopRecording}
        backtodashboard={backtodashboard}
        loader={loader}
        leaveCall={leaveCall}
      ///captureAgoraImg={captureAgoraImg}
      //listS3Objects={listS3Objects}
      />
    </>
  );
};

export default NotifyView;
