import React, { useContext, useEffect, useState } from 'react';
import WelcomeAuditScreen from './Component/welcomeAudit'
import { useParams, useHistory } from 'react-router-dom';
import { apiCall, getLocation } from '../utils/httpClient';
import Swal from 'sweetalert2'

import apiEndPoints from '../utils/apiEndPoints';
import { AgoraContext } from '../../Context/AgoraContext';
const WelcomeAuditView = (props) => {
    const [token, settoken, channel, setchannel, calluid, setcalluid, callsid, setcallsid, resourceIds, setresourceIds] = useContext(AgoraContext)
    console.log("🚀 ~ WelcomeAuditView ~ resourceIds:", resourceIds)
    console.log("🚀 ~ WelcomeAuditView ~ callsid:", callsid)
    console.log("🚀 ~ WelcomeAuditView ~ calluid:", calluid)
    console.log("🚀 ~ WelcomeAuditView ~ channel:", channel)
    console.log("🚀 ~ WelcomeAuditView ~ token:", token)
    let { audit_id } = useParams();
    const history = useHistory();
    useEffect(() => {
        _agoraToken()
    }, [])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("🚀 ~ useEffect ~ position:", position)
            
          },
          (error) => {
            console.log(error);
          }
        );
      }, []);


    const _agoraToken = async () => {
        try {
            const params = {
                audit_id: audit_id,
            }
            const response = await apiCall("POST", apiEndPoints.AGORA_TOKEN, params)
            // console.log("Token:",response.data.data)
            if (response.status === 200) {
                setchannel(response.data.data.channel)
                settoken(response.data.data.token)
                await localStorage.setItem('channel', response.data.data.channel);
                await localStorage.setItem('token', response.data.data.token);
            }
        } catch (error) {
            console.log("ERROR ", error)
        }
    }
   

    const handlenotify = () => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    console.log(result);
                    if (result.state === "granted" || result.state === "prompt") {

                        const params = {
                            audit_id: audit_id,
                            latitude: '',
                            longitude: '',
                        };
                        console.log("🚀 ~ file: index.js:299 ~ handleCaptureImage ~ params:", params)
                        navigator.geolocation.getCurrentPosition(async function (position) {
                            console.log("🚀 ~ position:", position)

                            if (position.coords.latitude !== '' && position.coords.longitude !== '') {

                                await localStorage.setItem('latitude', position.coords.latitude);
                                await localStorage.setItem('longitude', position.coords.longitude);
                                const resaddress = await getLocation(position.coords.latitude, position.coords.longitude);
                                console.log("🚀 ~ resaddress:-------", resaddress)
                                await localStorage.setItem('locationaddress', resaddress);

                                history.push("/notify/" + audit_id);

                                /* params.currentlat = position.coords.latitude
                                params.currentlong = position.coords.longitude

                                const response = await apiCall("POST", apiEndPoints.CHECKBMLOCATION, params)

                                if (response.status === 200) {
                                    history.push("/notify/" + audit_id);
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: response.data.message,
                                        confirmButtonColor: "#457fff"

                                    });
                                } */

                            }else{
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: 'Enable your device, location services and grant the necessary permissions 1',
                                    confirmButtonColor: "#457fff"
        
                                });
        
                            }

                        })
                    } else if (result.state === "denied") {

                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: 'Enable your device, location services and grant the necessary permissions',
                            confirmButtonColor: "#457fff"

                        });

                        
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        } 

        //history.push("/notify/" + audit_id);
       
    }
    const handlenotify_new = () => {
        history.push("/notify/" + audit_id);
    }
    return (<WelcomeAuditScreen
        handlenotify={handlenotify}

    />)
}
export default WelcomeAuditView;